exports.components = {
  "component---src-pages-25-h-java-js": () => import("./../../../src/pages/25h-java.js" /* webpackChunkName: "component---src-pages-25-h-java-js" */),
  "component---src-pages-26-h-java-js": () => import("./../../../src/pages/26h-java.js" /* webpackChunkName: "component---src-pages-26-h-java-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-build-modules-js": () => import("./../../../src/pages/build-modules.js" /* webpackChunkName: "component---src-pages-build-modules-js" */),
  "component---src-pages-demos-js": () => import("./../../../src/pages/demos.js" /* webpackChunkName: "component---src-pages-demos-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-past-talks-js": () => import("./../../../src/pages/past-talks.js" /* webpackChunkName: "component---src-pages-past-talks-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-channel-js": () => import("./../../../src/templates/channel.js" /* webpackChunkName: "component---src-templates-channel-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-talk-js": () => import("./../../../src/templates/talk.js" /* webpackChunkName: "component---src-templates-talk-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */)
}

