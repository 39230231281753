module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":"▚","className":"header-id-link","removeAccents":true,"enableCustomId":true,"offsetY":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://matomo.nipafx.dev","siteUrl":"https://nipafx.dev","disableCookies":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"nipafx.dev - You. Me. Java.","short_name":"nipafx.dev","description":"Website of Nicolai (aka nipafx), a Java enthusiast with a passion for learning and sharing, online and offline","start_url":"/","icon":"src/images/favicon.png","background_color":"#262429","theme_color":"#69ea7d","display":"minimal-ui","categories":["software","development","programming","java"],"lang":"en-US","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"967f34839081f843e6062f928c173f2e"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
